<!-- Content Wrapper. Contains page content -->
<div class="content-wrapper">
  <!-- Content Header (Page header) -->
  <div class="content-header">
    <div class="container-fluid">
      <div class="row mb-2">
        <div class="d-flex justify-content-between col-12">
          <h1 class="m-0 text-dark">Announcements</h1>
          <button class="btn btn-primary" data-toggle="modal" data-target="#newAnnouncement" (click)="clearNewAnnouncementForm()">
            + New Announcement
          </button>
        </div>
      </div><!-- /.row -->
    </div><!-- /.container-fluid -->
  </div>
  <!-- /.content-header -->

  <!-- Main content -->
  <section class="content">
    <div class="container-fluid">
      <!-- Small boxes (Stat box) -->
      <div class="row">
        <div class="col-12">
          <div class="card">
            <div class="card-body">
              <div class="table-responsive">
                <table class="table table-hover announcement-table">
                  <thead>
                    <tr>
                      <th scope="col">#</th>
                      <th scope="col">Image</th>
                      <th scope="col">Title</th>
                      <th scope="col">Start Date</th>
                      <th scope="col">End Date</th>
                      <th scope="col">Status</th>
                      <th scope="col">Delete</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr *ngFor="let announcement of displayList">
                      <th scope="row" data-toggle="modal" data-target="#updateAnnouncement"
                      (click)="setTarget(announcement)">{{announcement.index}}</th>
                      <td data-toggle="modal" data-target="#updateAnnouncement"
                      (click)="setTarget(announcement)">
                        <img
                          class="img-thumbnail record-image"
                          src="{{ announcement.imageUrl }}"
                          appImgFallback="../../assets/dist/img/default-150x150.png"
                          alt="Announcement Image"
                        />
                      </td>
                      <td data-toggle="modal" data-target="#updateAnnouncement"
                      (click)="setTarget(announcement)">{{announcement.title}}</td>
                      <td data-toggle="modal" data-target="#updateAnnouncement"
                      (click)="setTarget(announcement)">{{announcement.startDate | date: 'dd/MM/yyyy'}}</td>
                      <td data-toggle="modal" data-target="#updateAnnouncement"
                      (click)="setTarget(announcement)">{{announcement.endDate | date: 'dd/MM/yyyy'}}</td>
                      <td data-toggle="modal" data-target="#updateAnnouncement"
                      (click)="setTarget(announcement)">
                        <span *ngIf="announcement.status === 'INACTIVE' || announcement.status === 'PENDING'" class="text-muted">Inactive</span>
                        <span *ngIf="announcement.status === 'ACTIVE'" class="text-success">Active</span>
                      </td>
                      <td>
                          <button class="btn btn-danger" data-toggle="modal" (click)="setTarget(announcement)" data-target="#deleteAnnouncement">
                              delete</button>
                              <!--<button class="btn btn-danger" (click)="openModal()">
                              delete</button>-->
                        <!--<button type="button" class="btn btn-danger" (click)="delAnnouncement(announcement)">Delete</button>-->
                      </td>
                    </tr>
                  </tbody>
                </table>

                <div class="d-flex w-100 justify-content-end">
                  <nav aria-label="Page navigation">
                    <ul class="pagination">
                      <li class="page-item" [ngClass]="{disabled: page === 1}">
                        <a class="page-link" (click)="changePage(page-1)" aria-label="Previous">
                          <span aria-hidden="true">&laquo;</span>
                          <span class="sr-only">Previous</span>
                        </a>
                      </li>
                      <li class="page-item" *ngIf="page === maxPage && maxPage >= 3"><a class="page-link" (click)="changePage(page-2)">{{page - 2}}</a></li>
                      <li class="page-item" *ngIf="page > 1 && maxPage >= 2"><a class="page-link" (click)="changePage(page-1)">{{page - 1}}</a></li>
                      <li class="page-item active"><a class="page-link" (click)="changePage(page)">{{page}}</a></li>
                      <li class="page-item" *ngIf="page < maxPage && maxPage >= 2"><a class="page-link" (click)="changePage(page+1)">{{page + 1}}</a></li>
                      <li class="page-item" *ngIf="page === 1 && maxPage >= 3"><a class="page-link" (click)="changePage(page+2)">{{page + 2}}</a></li>
                      <li class="page-item" [ngClass]="{disabled: page === maxPage}">
                        <a class="page-link" (click)="changePage(page+1)" aria-label="Next">
                          <span aria-hidden="true">&raquo;</span>
                          <span class="sr-only">Next</span>
                        </a>
                      </li>
                    </ul>
                  </nav>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</div>

<!-- Modal -->
<div class="modal fade" id="newAnnouncement" tabindex="-1" role="dialog" aria-labelledby="newAnnouncementLabel" aria-hidden="true">
  <div class="modal-dialog" role="document">
    <form class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" id="newAnnouncementLabel">Create New Announcement</h5>
        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <div class="form-group">
          <label for="title">Title</label>
          <input [(ngModel)]="newAnnouncement.title" [ngModelOptions]="{standalone: true}" class="form-control" id="title">
        </div>
        <div class="form-group">
          <label for="image">Image</label>
          <div class="input-group mb-3">
            <div class="custom-file">
              <input [(ngModel)]="newAnnouncement.image" [ngModelOptions]="{standalone: true}" [disabled]="uploading" observeFiles type="file" accept="image/*" class="custom-file-input" id="image">
              <label class="custom-file-label" for="image">{{newAnnouncement.image ? newAnnouncement.image.name : "Choose Image"}}</label>
            </div>
            <div class="input-group-append">
              <span class="input-group-text" id="" (click)="uploadImage()">{{ uploading ? "Uploading" : "Upload" }}</span>
            </div>
          </div>
          <a href="{{newAnnouncement.imageUrl}}" target="_blank" *ngIf="newAnnouncement.imageUrl">
            <img class="img-thumbnail record-image"
              src="{{ newAnnouncement.imageUrl }}"
              appImgFallback="../../assets/dist/img/default-150x150.png"
              alt="Announcement Image"
            />
          </a>
        </div>
        <div class="form-group">
          <label for="name">Start Date</label>
          <input (input)="newAnnouncement.startDate = $event.target.value" type="date" [value]="newAnnouncement.startDate | date:'yyyy-MM-dd'" class="form-control" id="startDate">
        </div>
        <div class="form-group">
          <label for="name">End Date</label>
          <input (input)="newAnnouncement.endDate = $event.target.value" type="date" [value]="newAnnouncement.endDate | date:'yyyy-MM-dd'" class="form-control" id="endDate">
        </div>
        <!--<div class="form-group">
          <label for="status">Status</label>
          <select [(ngModel)]="newAnnouncement.status" [ngModelOptions]="{standalone: true}" class="form-control" id="status">
            <option value="active">Active</option>
            <option value="inactive">Inactive</option>
          </select>
        </div>-->
      </div>
      <div class="modal-footer">
        <button type="submit" class="btn btn-primary" data-dismiss="modal"
          [disabled]="(newAnnouncement.title == '' || newAnnouncement.imageUrl == '' || newAnnouncement.startDate == '' || newAnnouncement.endDate == '')"
          (click)="createNewAnnouncement()">Create</button>
          <button type="button" class="btn btn-secondary" data-dismiss="modal">Close</button>
      </div>
    </form>
  </div>
</div>

<!-- Modal -->
<div class="modal fade" id="updateAnnouncement" tabindex="-1" role="dialog" aria-labelledby="updateAnnouncementLabel" aria-hidden="true">
  <div class="modal-dialog" role="document" *ngIf="selectedAnnouncement">
    <form class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" id="updateAnnouncementLabel">Update Announcement</h5>
        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <div class="form-group">
          <label for="title">Title</label>
          <input [(ngModel)]="selectedAnnouncement.title" [ngModelOptions]="{standalone: true}" class="form-control" id="title">
        </div>
        <div class="form-group">
          <label for="image">New Image</label>
          <div class="input-group mb-3">
            <div class="custom-file">
              <input [(ngModel)]="selectedAnnouncement.image" [ngModelOptions]="{standalone: true}" [disabled]="uploading" observeFiles type="file" accept="image/*" class="custom-file-input" id="image">
              <label class="custom-file-label" for="image">{{selectedAnnouncement.image ? selectedAnnouncement.image.name : "Choose Image"}}</label>
            </div>
            <div class="input-group-append">
              <span class="input-group-text" id="" (click)="uploadImage()">{{ uploading ? "Uploading" : "Upload" }}</span>
            </div>
          </div>
          <a href="{{selectedAnnouncement.imageUrl}}" target="_blank">
            <img class="img-thumbnail record-image"
              src="{{ selectedAnnouncement.imageUrl }}"
              appImgFallback="../../assets/dist/img/default-150x150.png"
              alt="Announcement Image"
            />
          </a>
        </div>
        <div class="form-group">
          <label for="name">Start Date</label>
          <input (input)="selectedAnnouncement.endDate = $event.target.value" type="date" [value]="selectedAnnouncement.startDate | date:'yyyy-MM-dd'" class="form-control" id="startDate">
        </div>
        <div class="form-group">
          <label for="name">End Date</label>
          <input (input)="selectedAnnouncement.endDate = $event.target.value" [value]="selectedAnnouncement.endDate | date:'yyyy-MM-dd'"  type="date" class="form-control" id="endDate">
        </div>
        <!--<div class="form-group">
          <label for="status">Status</label>
          <select [(ngModel)]="selectedAnnouncement.status" [ngModelOptions]="{standalone: true}" class="form-control" id="status">
            <option value="active">Active</option>
            <option value="inactive">Inactive</option>
          </select>
        </div>-->
      </div>
      <div class="modal-footer">
        <button type="submit" class="btn btn-primary" data-dismiss="modal"
          [disabled]="(selectedAnnouncement.title == '' || selectedAnnouncement.startDate == '' || selectedAnnouncement.endDate == '')"
          (click)="updateAnnouncement()">Update</button>
          <button type="button" data-dismiss="modal" #confirmDelete class="btn btn-danger" (click)="delAnnouncement(selectedAnnouncement)">Delete</button>
          <button type="button" class="btn btn-secondary" data-dismiss="modal">Close</button>
      </div>
    </form>
  </div>
</div>

<div class="modal fade" id="deleteAnnouncement" tabindex="-1" role="dialog" aria-labelledby="deleteAnnouncementLabel" aria-hidden="true">
  <div class="modal-dialog" role="document" *ngIf="selectedAnnouncement">
    <form class="modal-content">
      <div class="modal-header">
        <h4 class="modal-title">Confirm Delete Announcement</h4>
        <button type="button" class="close" aria-label="Close" class="close" data-dismiss="modal">
            <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <p>Are you want to delete</p>
      </div>
      <div class="modal-footer">
        <button type="button" data-dismiss="modal" #confirmDelete class="btn btn-outline-dark" (click)="delAnnouncement(selectedAnnouncement)">Yes</button>
        <button type="button" class="btn btn-outline-dark" data-dismiss="modal" aria-label="Close">No</button>
      </div>
    </form>
</div>
<!--<ngbd-modal-content></ngbd-modal-content>-->
<ng-template #customCellTemplate let-day="day" let-locale="locale">
  <div class="cal-cell-top">
    <span class="cal-day-badge" *ngIf="day.badgeTotal > 0">{{ day.badgeTotal }}</span>

    <span class="cal-day-number">{{ day.date | calendarDate:'monthViewDayNumber':locale }}</span>
  </div>

  <small (click)="episodeClick('Clicked', event)" style="margin: 5px" *ngFor="let event of day.events;">
    <b>{{event.start | date: 'HH:mm' }}</b> {{event.title}}
  </small>

</ng-template>

<mwl-utils-calendar-header 
  [(view)]="view" 
  [(viewDate)]="viewDate" 
  (viewDateChange)="fetchEvents()"
  (viewChange)="fetchEvents()">
</mwl-utils-calendar-header>

<ng-template #loading>
  <div class="text-center" #load>
    <i class="fa fa-spin fa-spinner fa-5x"></i> <br />
    Loading events...
  </div>
</ng-template>
<div *ngIf="!(isLoading$ | async); else loading;">

  <div [ngSwitch]="view">
    <mwl-calendar-month-view *ngSwitchCase="CalendarView.Month" 
      [viewDate]="viewDate" 
      [events]="events"
      [refresh]="refresh" 
      (dayClicked)="handleEvent('Clicked', $event.day)"
      (eventTimesChanged)="eventTimesChanged($event)" 
      [cellTemplate]="customCellTemplate">
    </mwl-calendar-month-view>
    <mwl-calendar-week-view *ngSwitchCase="CalendarView.Week" 
      [viewDate]="viewDate" 
      [events]="events"
      [refresh]="refresh" 
      (eventClicked)="handleEvent('Clicked', $event.event)"
      (eventTimesChanged)="eventTimesChanged($event)"
      (hourSegmentClicked)="handleHourEvent('Clicked', $event)">
    </mwl-calendar-week-view>
    <mwl-calendar-day-view *ngSwitchCase="CalendarView.Day" 
      [viewDate]="viewDate" 
      [events]="events" 
      [refresh]="refresh"
      (eventClicked)="handleEvent('Clicked', $event.event)" 
      (eventTimesChanged)="eventTimesChanged($event)">
    </mwl-calendar-day-view>
  </div>
</div>

<ng-template #modalContent let-close="close" aria-hidden="true">
  <div class="modal-header">
    <h5 class="modal-title">Edit Events</h5>
    <button type="button" class="close" (click)="close()">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <div *ngFor="let item of modalData.updateEvents; let i = index">
      <div class="card-body">
        <div class="form-row">
          <div class="form-group col-md-6">
            <label for="startDate">Start Time</label>
            <input [(ngModel)]="item.start" type="time" [value]="item.start" class="form-control" required>
          </div>
          <div class="form-group col-md-6">
            <label for="endDate">End Time</label>
            <input [(ngModel)]="item.end" type="time" [value]="item.end" class="form-control" required>
          </div>
        </div>
        <div class="form-group">
          <label for="title">Title</label>
          <input [(ngModel)]="item.title" type="text" class="form-control" (input)="item.title" [value]="item.title"
            required>
        </div>
        <div class="form-group">
          <label for="title">Description</label>
          <input [(ngModel)]="item.description" type="text" class="form-control" (input)="item.description" [value]="item.description"
            required>
        </div>
        <div class="form-group">
          <label for="title">Time</label>
          <input [(ngModel)]="item.time" type="text" class="form-control" (input)="item.time" [value]="item.time"
            required>
        </div>
        <button type="button" class="btn btn-danger" data-dismiss="modal" (click)="deleteEvent(item)">
          Delete
        </button>
        <div class="float-right">
          <button type="button" style="margin-right: 20px;" class="btn btn-primary" data-dismiss="modal" (click)="updateEvent()">
            Update
          </button>
          <button type="button" class="btn btn-secondary" data-dismiss="modal" (click)="close()">Close</button>
        </div>
        
      </div>
    </div>
  </div>
  <!-- <div class="modal-footer">
    
  </div> -->
</ng-template>


<ng-template #modalAdd let-close="close" aria-hidden="true">
  <div class="modal-header">
    <h5 class="modal-title">Add Events</h5>
    <button type="button" class="close" (click)="close()">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
      <div class="card-body">
        <div class="form-row">
          <div class="form-group col-md-6">
            <label for="startDate">Start Time</label>
            <input [(ngModel)]="newEvent.start" type="time" [value]="newEvent.start" class="form-control" required>
          </div>
          <div class="form-group col-md-6">
            <label for="endDate">End Time</label>
            <input [(ngModel)]="newEvent.end" type="time" [value]="newEvent.end" class="form-control" required>
          </div>
        </div>
        <div class="form-group">
          <label for="title">Title</label>
          <input [(ngModel)]="newEvent.title" type="text" class="form-control" (input)="newEvent.title" [value]="newEvent.title"
            required>
        </div>
        <div class="form-group">
          <label for="title">Description</label>
          <input [(ngModel)]="newEvent.description" type="text" class="form-control" (input)="newEvent.description" [value]="newEvent.description"
            required>
        </div>
        <div class="form-group">
          <label for="title">Time</label>
          <input [(ngModel)]="newEvent.time" type="text" class="form-control" (input)="newEvent.time" [value]="newEvent.time"
            required>
        </div>
      </div>
  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-primary" data-dismiss="modal" (click)="addEvent()">
      Add
    </button>
    <button type="button" class="btn btn-secondary" data-dismiss="modal" (click)="close()">Close</button>
  </div>
</ng-template>
<!-- Content Wrapper. Contains page content -->
<div class="content-wrapper">
  <!-- Content Header (Page header) -->
  <div class="content-header">
    <div class="container-fluid">
      <div class="row mb-2">
        <div class="d-flex justify-content-between col-12">
          <h1 class="m-0 text-dark">About Us</h1>
        </div>
      </div><!-- /.row -->
    </div><!-- /.container-fluid -->
  </div>
  <!-- /.content-header -->

  <!-- Main content -->
  <section class="content">
    <div class="container-fluid">
      <!-- Small boxes (Stat box) -->
      <div class="row">
        <div class="col-12">
          <div class="card">
            <div class="card-body">

                <div class="form-group">
                    <label for="image">About Logo</label>
                    <div class="input-group mb-3">
                        <div class="custom-file">
                            <input [(ngModel)]="image" [ngModelOptions]="{standalone: true}" [disabled]="uploading" observeFiles type="file" accept="image/*" class="custom-file-input" id="image">
                            <label class="custom-file-label" for="image">{{image ? image.name : "Choose Image"}}</label>
                        </div>
                        <div class="input-group-append">
                            <span class="input-group-text" id="" (click)="uploadImage()">{{ uploading ? "Uploading" : "Upload" }}</span>
                        </div>
                    </div>

                    <a href="{{ logo }}" target="_blank">
                        <img class="img-thumbnail record-image"
                            src="{{ logo }}"
                            appImgFallback="../../assets/dist/img/default-150x150.png"
                            alt="About Logo"
                        />
                    </a>
                </div>
                <!--
                <div class="form-group">
                    <label for="bkg">Background color</label>
                    <input [(ngModel)]="bkg" type="text" class="form-control colorpicker" id="bkg" placeholder="0xFFFFFFFF">
                </div>
                -->

                <div class="form-group">
                    <label for="body">Detail</label>
                    <textarea [(ngModel)]="body" id="body" class="form-control" rows="8" placeholder="Detail ..." ></textarea>
                </div>

                <div class="box">
                    <div class="box-header with-border">
                        <h3 class="box-title">Contact Us</h3>
                    </div>
                    <div class="box-body">
                        <div class="form-group">
                            <label for="address">Address</label>
                            <input [(ngModel)]="address" type="text" class="form-control" id="address" placeholder="Enter address">
                        </div>
                        <div class="form-group">
                            <label for="phone">Phone</label>
                            <input [(ngModel)]="phone" type="text" class="form-control" id="phone" placeholder="Enter phone">
                        </div>
                        <div class="form-group">
                            <label for="email">Email</label>
                            <input [(ngModel)]="email" type="email" class="form-control" id="email" placeholder="Enter email">
                        </div>
                    </div>
                </div>
                
            </div>
            <div class="card-footer">
                <button type="submit" class="btn btn-primary"
                (click)="updateAboutUs()">Update</button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</div>

import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Configuration } from '../../config/app.constant';
import { Observable, of as observableOf  } from 'rxjs';
import { AngularFirestore } from '@angular/fire/firestore';


@Injectable({
  providedIn: 'root'
})
export class AuthenService {
  private actionUrl: string;
  loggedId = false;

  constructor(
    private http: HttpClient,
    private configuration: Configuration,
    private firestore: AngularFirestore,
  ) {}

  public getToken() {
    const user = JSON.parse(localStorage.getItem('tc_admin_auth_info')) || {};
    return user.api_token;
  }
  public getRole() {
    const user = JSON.parse(localStorage.getItem('tc_admin_auth_info')) || {};
    return user.role;
  }

  public login(user, pass) {
    this.actionUrl = this.configuration.ServerWithApiUrl + 'login-admin';
    return this.http.post(this.actionUrl, {username: user, password: pass});
  }

  public getUserEmail() {
    const user = JSON.parse(localStorage.getItem('tc_admin_auth_info')) || {};
    return user.email;
  }

  // public isAutenticated() {
  //   const promise = new Promise(
  //     (resolve, reject) => {
  //       setTimeout(() => {
  //         resolve(this.loggedId);
  //       }, 5000);
  //     }
  //   );
  //   return promise;
  // }

  public isAutenticated() {
    const loggedInuser = JSON.parse(localStorage.getItem('tc_admin_auth_info'));
    return this.firestore.collection('users').ref
    .where('email', '==', loggedInuser.email)
    .where('status', '==', 'active')
    .get();
  }
}

import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import { AngularFireStorage, AngularFireUploadTask } from '@angular/fire/storage';
import '@angular/localize/init';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { finalize } from 'rxjs/operators';
import { AlertModalContent } from '../components/modal/alert-modal.component';
import { AnnouncementsService } from './announcements.service';

const PAGE_SIZE = 10;

interface Announcement {
  index: number;
  id: string;
  title: string;
  startDate: string;
  endDate: string;
  image: any;
  imageUrl: string;
  status: string;
}

@Component({
  selector: 'app-announcements',
  templateUrl: './announcements.component.html',
  styleUrls: ['./announcements.component.scss'],
})
export class AnnouncementsComponent implements OnInit {
  
  
 //ngbdModalContent: NgbdModalContent;
  announcements: Announcement[];
  newAnnouncement: Announcement;
  selectedAnnouncement: Announcement;
  displayList: Announcement[];
  page: number;
  maxPage: number;
  error: any;
  data: any;
  uploadTask: AngularFireUploadTask;
  uploading: boolean;
  
  constructor(
    private modalService: NgbModal,
    private announcementService: AnnouncementsService,
    private firestore: AngularFirestore,
    private storage: AngularFireStorage,
    
  ) {
    this.clearNewAnnouncementForm();
    this.announcements = [];
    this.maxPage = 1;
    this.changePage(1);
  }

  ngOnInit() {
    this.reloadList();
  }
  
  alertModal() {
    const modalRef = this.modalService.open(AlertModalContent);
    modalRef.componentInstance.id = 'xxxx';
    modalRef.componentInstance.message = 'cccccc';
    modalRef.result.then((result) => {
      if (result) {
        console.log(result);
      }
    });
  }

  uploadImage() {
    if((!this.newAnnouncement.image && !this.selectedAnnouncement.image) ||  this.uploading) return;
    let image: any
    let newAnnouncement: boolean
    if(this.newAnnouncement.image){
      image = this.newAnnouncement.image;
      newAnnouncement = true;
    }else{
      image = this.selectedAnnouncement.image;
      newAnnouncement = false;
    }
    let fileType = image.type.split('/')[1];
    let filename = `${String(new Date().getTime())}.${fileType}`;
    let uploadTo = `/images/announcements/${filename}`;

    const ref = this.storage.ref(uploadTo);
    this.uploadTask = this.storage.upload(uploadTo, image);
    this.uploading = true;
    this.uploadTask.percentageChanges();
    this.uploadTask.snapshotChanges().pipe(
      finalize(async() =>  {
        let downloadURL = await ref.getDownloadURL().toPromise();
        this.uploading = false;
        if(newAnnouncement){
          this.newAnnouncement.imageUrl = downloadURL;
        }else{
          this.selectedAnnouncement.imageUrl = downloadURL;
        }
      })).subscribe();
  }
  changePage(page) {
    this.page = page;
    this.displayList = this.announcements.slice((page-1)*PAGE_SIZE, page*PAGE_SIZE);
  }
  createNewAnnouncement() {
    this.firestore.collection('announcements').add({
      title: this.newAnnouncement.title,
      start_date: new Date(this.newAnnouncement.startDate),
      end_date: new Date(this.newAnnouncement.endDate),
      image_url: this.newAnnouncement.imageUrl,
    }).then(querySnapshot => {
      this.reloadList();
    });
  }
  reloadList() {
    const querySnapshot = this.firestore.collection('announcements', ref => ref.orderBy('start_date', 'desc')).get().subscribe(querySnapshot => {
      let data: any = querySnapshot.docs.map(doc => ({...doc.data(), id: doc.id}));
      this.announcements = data.map(function(e, i) {
        let fromDate = e.start_date.toDate();
        let toDate = e.end_date.toDate();
        let today = new Date();
        let status = '';
        if(today >= fromDate && today <= toDate){
          status = 'ACTIVE';
        }else if(today < fromDate){
          status = 'PENDING';
        }else if(today > toDate){
          status = 'INACTIVE';
        }
        return {
          index: i + 1,
          id: e.id,
          title: e.title,
          startDate: fromDate,
          endDate: toDate,
          image: null,
          imageUrl: e.image_url,
          status: status
      }});
      this.maxPage = Math.ceil(this.announcements.length/PAGE_SIZE) || 1;
      this.changePage(1);
    });
  }
  updateAnnouncement() {
    this.firestore.doc(`announcements/${this.selectedAnnouncement.id}`).update({
      title: this.selectedAnnouncement.title,
      start_date: new Date(this.selectedAnnouncement.startDate),
      end_date: new Date(this.selectedAnnouncement.endDate),
      image_url: this.selectedAnnouncement.imageUrl,
      status: this.selectedAnnouncement.status
    }).then(querySnapshot => {
      this.reloadList();
    })
  }
  clearNewAnnouncementForm() {
    this.newAnnouncement = {
      index: 0,
      id: '',
      title: '',
      startDate: '',
      endDate: '',
      image: null,
      imageUrl: '',
      status: 'active'
    }
  }
  setTarget(announcement) {
    let tmp = {...announcement}
    this.selectedAnnouncement = tmp;
  }

  delAnnouncement(announcement){
    this.firestore.doc(`announcements/${announcement.id}`).delete()
    .catch(error => {console.log(error)})
    .then(() => {
      this.storage.storage.refFromURL(announcement.imageUrl).delete()
      .catch(error => {console.log(error)}).then(
        () => {    
          this.reloadList();
        }
      );
    });  
  }

}

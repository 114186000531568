import { ModuleWithProviders } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { MainComponent } from './main/main.component';
import { DashboardComponent } from './dashboard/dashboard.component';
import { AnnouncementsComponent } from './announcements/announcements.component';
import { ProgramsComponent } from './programs/programs.component';
// import { ManageAdminComponent } from './manage-admin/manage-admin.component';
import { NotFoundComponent } from './not-found/not-found.component';
import { LoginComponent } from './authen/login/login.component';
import {AuthenGuard} from './guards/authen.guard';
import {RedirectGuard} from './guards/redirect.guard';
import { ImportschedulesComponent } from './importschedules/importschedules.component';
import { UsersComponent } from './users/users.component';
import { SchedulesComponent } from './schedules/schedules.component';
import { AboutUsComponent } from './about-us/about-us.component';

const AppRoutes: Routes = [
  { path: '', redirectTo: 'importschedules', pathMatch: 'full' },
  {
    path: '',
    component: MainComponent,
    children: [
      // { path: 'dashboard', component: DashboardComponent, canActivate: [ AuthenGuard ] },
      { path: 'announcements', component: AnnouncementsComponent, canActivate: [ AuthenGuard ] },
      { path: 'programs', component: ProgramsComponent, canActivate: [ AuthenGuard ] },
      { path: 'importschedules', component: ImportschedulesComponent, canActivate: [ AuthenGuard ] },
      { path: 'users', component: UsersComponent, canActivate: [ AuthenGuard ] },
      { path: 'schedules', component: SchedulesComponent, canActivate: [ AuthenGuard ] },
      { path: 'aboutus', component: AboutUsComponent, canActivate: [ AuthenGuard ] },
      // { path: 'manage/admin', component: ManageAdminComponent, canActivate: [ AuthenGuard ] }
    ],
  },
  { path: 'login', component: LoginComponent },
  { path: '**', component: NotFoundComponent},
];

export const AppRouting: ModuleWithProviders = RouterModule.forRoot(AppRoutes);

<!-- Content Wrapper. Contains page content -->
<div class="content-wrapper">
  <!-- Content Header (Page header) -->
  <div class="content-header">
    <div class="container-fluid">
      <div class="row mb-2">
        <div class="d-flex justify-content-between col-12">
          <h1 class="m-0 text-dark">Users</h1>
          <button class="btn btn-primary" data-toggle="modal" data-target="#newUser" (click)="clearNewUserForm()">
            + New User
          </button>
        </div>
      </div><!-- /.row -->
    </div><!-- /.container-fluid -->
  </div>
  <!-- /.content-header -->

  <!-- Main content -->
  <section class="content">
    <div class="container-fluid">
      <!-- Small boxes (Stat box) -->

      <div class="row">
        <div class="col-12">
          <div class="card">
            <div class="card-body">
              <div class="table-responsive">

                <table class="table table-hover user-table">
                  <thead>
                    <tr>
                      <th scope="col">Email</th>
                      <th scope="col">Status</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr *ngFor="let user of displayList" data-toggle="modal" data-target="#updateUser"
                      (click)="setTarget(user)">
                      <td scope="row">{{user.email}}</td>
                      <td scope="row">{{user.status}}</td>
                    </tr>
                  </tbody>
                </table>

                <div class="d-flex w-100 justify-content-end">
                  <nav aria-label="Page navigation">
                    <ul class="pagination">
                      <li class="page-item" [ngClass]="{disabled: page === 1}">
                        <a class="page-link" (click)="changePage(page-1)" aria-label="Previous">
                          <span aria-hidden="true">&laquo;</span>
                          <span class="sr-only">Previous</span>
                        </a>
                      </li>
                      <li class="page-item" *ngIf="page === maxPage && maxPage >= 3"><a class="page-link"
                          (click)="changePage(page-2)">{{page - 2}}</a></li>
                      <li class="page-item" *ngIf="page > 1 && maxPage >= 2"><a class="page-link"
                          (click)="changePage(page-1)">{{page - 1}}</a></li>
                      <li class="page-item active"><a class="page-link" (click)="changePage(page)">{{page}}</a></li>
                      <li class="page-item" *ngIf="page < maxPage && maxPage >= 2"><a class="page-link"
                          (click)="changePage(page+1)">{{page + 1}}</a></li>
                      <li class="page-item" *ngIf="page === 1 && maxPage >= 3"><a class="page-link"
                          (click)="changePage(page+2)">{{page + 2}}</a></li>
                      <li class="page-item" [ngClass]="{disabled: page === maxPage}">
                        <a class="page-link" (click)="changePage(page+1)" aria-label="Next">
                          <span aria-hidden="true">&raquo;</span>
                          <span class="sr-only">Next</span>
                        </a>
                      </li>
                    </ul>
                  </nav>
                </div>

              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</div>

<!-- Modal -->
<div class="modal fade" id="newUser" tabindex="-1" role="dialog" aria-labelledby="newUserLabel" aria-hidden="true">
  <div class="modal-dialog" role="document">
    <form class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" id="newUserLabel">Create New User</h5>
        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <div class="form-group">
          <label for="id">email</label>
          <input [(ngModel)]="newUser.email" [ngModelOptions]="{standalone: true}" class="form-control" id="id">
        </div>
        <!-- <div class="form-group">
                    <label for="title">Password</label>
                    <input [(ngModel)]="newUser.password" [ngModelOptions]="{standalone: true}" class="form-control"
                        id="title">
                </div> -->
        <!-- <div class="form-group">
                    <label for="description">Passwor Confirm</label>
                    <input [(ngModel)]="newUser.passwordConfirm" [ngModelOptions]="{standalone: true}"
                        class="form-control" id="description">
                </div> -->
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-secondary" data-dismiss="modal">Close</button>
        <button type="submit" class="btn btn-primary" data-dismiss="modal" [disabled]="(newUser.email == '')"
          (click)="createNewUser()">Create</button>
      </div>
    </form>
  </div>
</div>

<!-- Modal -->
<div class="modal fade" id="updateUser" tabindex="-1" role="dialog" aria-labelledby="updateUserLabel"
  aria-hidden="true">
  <div class="modal-dialog modal-lg" role="document" *ngIf="selectedUser">
    <form class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" id="updateProgramLabel">User Detail</h5>
        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>

      <div class="modal-body">
        <div class="form-group">
          <label for="id">Email</label>
          <input [(ngModel)]="selectedUser.email" [ngModelOptions]="{standalone: true}" class="form-control" id="id">
        </div>
        <div class="form-group">
          <label for="title">Status</label>
          <select class="form-control"
            name="userStatus"
            id="userStatus"
            [(ngModel)] = "selectedUser.status">
            <option *ngFor="let status of statusList" [value]="status">{{ status }}</option>
          </select>
          <!-- <input [(ngModel)]="selectedUser.status" [ngModelOptions]="{standalone: true}" class="form-control" id="title"> -->
        </div>
        <!-- <div class="form-group">
                    <label for="description">Passwor Confirm</label>
                    <input [(ngModel)]="newUser.passwordConfirm" [ngModelOptions]="{standalone: true}"
                        class="form-control" id="description">
                </div> -->
      </div>

      <div class="modal-footer">
        <button type="button" class="btn btn-danger" data-dismiss="modal" (click)="deleteUser()">Delete</button>
        <button type="button" class="btn btn-secondary" data-dismiss="modal">Close</button>
        <button type="submit" class="btn btn-primary" data-dismiss="modal" [disabled]="(selectedUser.email == '')"
          (click)="updateUser()">Update</button>
      </div>
    </form>
  </div>
</div>

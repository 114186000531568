import { Input, Output, EventEmitter, Component } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { AngularFirestore } from '@angular/fire/firestore';
import { AngularFireStorage, AngularFireUploadTask } from '@angular/fire/storage';


@Component({
    selector: 'ngbd-modal-content',
    template: `
      <div class="modal-header">
        <h4 class="modal-title">Hi there!</h4>
        <button type="button" class="close" aria-label="Close" (click)="activeModal.dismiss('Cross click')">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <p>{{message}}!</p>
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-outline-dark" (click)="activeModal.close('Close click')">Close</button>
      </div>
    `
  })
  export class AlertModalContent {
    @Input() id: string;
    @Input() message: string;
    @Output() passEntry: EventEmitter<any> = new EventEmitter();

    constructor(
      public activeModal: NgbActiveModal,
      private firestore: AngularFirestore,
    ) { }

    ngOnInit() {
      console.log(this.id);
    }
  }

  
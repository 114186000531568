import { Component, OnInit } from '@angular/core';

import { AuthenService } from '../../authen/authen.service';

@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.scss']
})
export class SidebarComponent implements OnInit {
  username: string;
  role: string;
  email: string;

  constructor(
    private authen: AuthenService,
  ) { }

  ngOnInit() {
    this.role = this.authen.getRole();
    this.email = this.authen.getUserEmail();
  }

}

<!-- Content Wrapper. Contains page content -->
<div class="content-wrapper">
  <!-- Content Header (Page header) -->
  <div class="content-header">
    <div class="container-fluid">
      <div class="row mb-2">
        <div class="d-flex justify-content-between col-12">
          <h1 class="m-0 text-dark">User Management</h1>
          <button class="btn btn-primary" data-toggle="modal" data-target="#newAdmin" (click)="clearNewAdminForm()">
            + New User
          </button>
        </div>
      </div><!-- /.row -->
    </div><!-- /.container-fluid -->
  </div>
  <!-- /.content-header -->

  <!-- Main content -->
  <section class="content">
    <div class="container-fluid">
      <!-- Small boxes (Stat box) -->
      <div class="row">
        <div class="col-12">
          <div class="card">
            <div class="card-body">
              <div class="table-responsive">
                <table class="table table-hover admin-table">
                  <thead>
                    <tr>
                      <th scope="col">#</th>
                      <th scope="col">Username</th>
                      <th scope="col">Role</th>
                      <th scope="col">Name</th>
                      <th scope="col">Status</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr *ngFor="let admin of admins"
                      data-toggle="modal" data-target="#updateUser"
                      (click)="setTarget(admin)"
                    >
                      <th scope="row">{{admin.index}}</th>
                      <td>{{admin.username}}</td>
                      <td>
                          <span *ngIf="admin.role === 'admin'">Admin</span>
                          <span *ngIf="admin.role === 'operator'">Operator</span>
                      </td>
                      <td>{{admin.name}}</td>
                      <td>
                        <span *ngIf="admin.status === 'inactive'" class="text-muted">Inactive</span>
                        <span *ngIf="admin.status === 'active'" class="text-success">Active</span>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</div>

<!-- Modal -->
<div class="modal fade" id="newAdmin" tabindex="-1" role="dialog" aria-labelledby="newAdminLabel" aria-hidden="true">
  <div class="modal-dialog" role="document">
    <form class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" id="newAdminLabel">Create New User</h5>
        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <div class="form-group">
          <label for="username">Username</label>
          <input [(ngModel)]="newAdmin.username" [ngModelOptions]="{standalone: true}" class="form-control" id="username">
        </div>
        <div class="form-group">
          <label for="password">Password</label>
          <input [(ngModel)]="newAdmin.password" [ngModelOptions]="{standalone: true}" type="password" class="form-control" id="password">
        </div>
          <div class="form-group">
            <label for="name">Name</label>
            <input [(ngModel)]="newAdmin.name" [ngModelOptions]="{standalone: true}" class="form-control" id="name">
          </div>
        <div class="form-group">
          <label for="role">Role</label>
          <select [(ngModel)]="newAdmin.role" [ngModelOptions]="{standalone: true}" class="form-control" id="role">
            <option value="admin">Admin</option>
            <option value="operator">Operator</option>
          </select>
        </div>
        <div class="form-group">
          <label for="status">Status</label>
          <select [(ngModel)]="newAdmin.status" [ngModelOptions]="{standalone: true}" class="form-control" id="status">
            <option value="active">Active</option>
            <option value="inactive">Inactive</option>
          </select>
        </div>
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-secondary" data-dismiss="modal">Close</button>
        <button type="submit" class="btn btn-primary" data-dismiss="modal"
          [disabled]="!(newAdmin.username && newAdmin.password && newAdmin.name)"
          (click)="createNewAdmin()">Create</button>
      </div>
    </form>
  </div>
</div>

<!-- Modal -->
<div class="modal fade" id="updateUser" tabindex="-1" role="dialog" aria-labelledby="updateUserLabel" aria-hidden="true">
  <div class="modal-dialog" role="document" *ngIf="selectedAdmin">
    <form class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" id="updateUserLabel">Update User ({{selectedAdmin.username}})</h5>
        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <div class="form-group">
          <label for="username">Username</label>
          <input [(ngModel)]="selectedAdmin.username" [ngModelOptions]="{standalone: true}" class="form-control" id="username" disabled>
        </div>
        <div class="form-group">
          <label for="password">New Password</label>
          <input [(ngModel)]="selectedAdmin.password" [ngModelOptions]="{standalone: true}" type="password" class="form-control" id="password">
        </div>
        <div class="form-group">
          <label for="name">Name</label>
          <input [(ngModel)]="selectedAdmin.name" [ngModelOptions]="{standalone: true}" class="form-control" id="name">
        </div>
        <div class="form-group">
          <label for="role">Role</label>
          <select [(ngModel)]="selectedAdmin.role" [ngModelOptions]="{standalone: true}" class="form-control" id="role">
            <option value="admin">Admin</option>
            <option value="operator">Operator</option>
          </select>
        </div>
        <div class="form-group">
          <label for="status">Status</label>
          <select [(ngModel)]="selectedAdmin.status" [ngModelOptions]="{standalone: true}" class="form-control" id="status">
            <option value="active">Active</option>
            <option value="inactive">Inactive</option>
          </select>
        </div>
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-secondary" data-dismiss="modal">Close</button>
        <button type="submit" class="btn btn-primary" data-dismiss="modal"
          [disabled]="!(selectedAdmin.username && selectedAdmin.name)"
          (click)="updateAdmin()">Update</button>
      </div>
    </form>
  </div>
</div>

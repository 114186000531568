import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';

import { AlertModalContent } from './alert-modal.component';

@NgModule({
  imports: [BrowserModule, NgbModule],
  declarations: [AlertModalContent],
  exports: [AlertModalContent],
  bootstrap: [AlertModalContent],
  entryComponents: [AlertModalContent]
})
export class AlertModalContentModule {}

import { Injectable } from '@angular/core';
import {CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, Router, ActivatedRoute} from '@angular/router';
import { Observable } from 'rxjs';
import { AngularFirestore } from '@angular/fire/firestore';
import { AuthenService } from '../authen/authen.service';

@Injectable({
  providedIn: 'root'
})
export class AuthenGuard implements CanActivate {
  users: any;
  constructor(private router: Router, private firestore: AngularFirestore, private auth: AuthenService) {}

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {

    const user = localStorage.getItem('tc_admin_auth_info');
    const userObject = JSON.parse(localStorage.getItem('tc_admin_auth_info'));

    if (!user) {
      return this.router.navigate(['login']);
    }

    return this.auth.isAutenticated()
      .then(res => {
        if (res.docs.length > 0) {
          return true;
        } else {
          return this.router.navigate(['login']);
        }
      }
      );

  }
}

import { Component, OnInit, ViewChild } from '@angular/core';
import { CalendarScheduleComponent } from '../components/schedule/schedule.component'
import {
  isSameMonth,
  isSameDay,
  startOfMonth,
  endOfMonth,
  startOfWeek,
  endOfWeek,
  startOfDay,
  endOfDay,
  format,
  subDays,
  addDays,
  addHours
} from 'date-fns';
import {
  CalendarEvent,
  CalendarEventAction,
  CalendarEventTimesChangedEvent,
  CalendarView,
} from 'angular-calendar';
import { HttpClient, HttpParams } from '@angular/common/http';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { SchedulesService } from './schedules.service'
import { Schedule } from '../models/schedule';
import { AngularFirestore } from '@angular/fire/firestore';
import { ModalData } from '../components/schedule/models/modalData';
import { DatePipe } from '@angular/common';

const colors: any = {
  red: {
    primary: '#ad2121',
    secondary: '#FAE3E3'
  },
  blue: {
    primary: '#1e90ff',
    secondary: '#D1E8FF'
  },
  yellow: {
    primary: '#e3bc08',
    secondary: '#FDF1BA'
  }
};

@Component({
  selector: 'app-schedules',
  templateUrl: './schedules.component.html',
  styleUrls: ['./schedules.component.scss']
})
export class SchedulesComponent implements OnInit {
  ev: CalendarEvent[]
  loadingFlg: boolean = false;

  constructor(
    public calendarScheduleComponent: CalendarScheduleComponent,
    public schedulesService: SchedulesService,
    private datepipe: DatePipe,
  ) { 
    
  }

  async ngOnInit() {
  
  }
  @ViewChild(CalendarScheduleComponent) child:CalendarScheduleComponent;
  async reloadSchedule(obj: any){
    await this.schedulesService.getSchedules(obj).then(r => {
      this.ev = r;
      //this.calendarScheduleComponent.events = this.ev;
      //this.calendarScheduleComponent.isLoading$.next(false);
      this.child.display(this.ev);
      this.loadingFlg = true;
    });
  }
  
  updateSchedule(data: ModalData){
    this.schedulesService.updateSchedule(data);
  }

  deleteEpisode(eventToDelete: CalendarEvent) {
    this.schedulesService.deleteEpisode(eventToDelete);
  }

}

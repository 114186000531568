import {
    Component,
    ChangeDetectionStrategy,
    ViewChild,
    TemplateRef,
    Input,
    EventEmitter,
    Output
  } from '@angular/core';
  import {
    isSameMonth,
    isSameDay,
    startOfMonth,
    endOfMonth,
    startOfWeek,
    endOfWeek,
    startOfDay,
    endOfDay,
    format,
    subDays,
    addDays,
    addHours
  } from 'date-fns';
  import { Observable, from, BehaviorSubject } from 'rxjs';
  import { HttpClient, HttpParams } from '@angular/common/http';
  import { Subject } from 'rxjs';
  import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
  import {
    CalendarEvent,
    CalendarEventAction,
    CalendarEventTimesChangedEvent,
    CalendarView
  } from 'angular-calendar';
import { map } from 'rxjs/operators';
import { colors } from './util/colors';
import { DatePipe } from '@angular/common';
import { UpdateEvent } from './models/updateEvent';
import { ModalData } from './models/modalData';


function getTimezoneOffsetString(date: Date): string {
  const timezoneOffset = date.getTimezoneOffset();
  const hoursOffset = String(
    Math.floor(Math.abs(timezoneOffset / 60))
  ).padStart(2, '0');
  const minutesOffset = String(Math.abs(timezoneOffset % 60)).padEnd(2, '0');
  const direction = timezoneOffset > 0 ? '-' : '+';

  return `T00:00:00${direction}${hoursOffset}:${minutesOffset}`;
}

@Component({
  selector: 'mwl-demo-component',
  changeDetection: ChangeDetectionStrategy.OnPush,
  templateUrl: 'template.html'
})
export class CalendarScheduleComponent {

    @ViewChild('modalContent', { static: true }) modalContent: TemplateRef<any>;
    @ViewChild('modalAdd', { static: true }) modalAdd: TemplateRef<any>;
    
    view: CalendarView = CalendarView.Month;
    CalendarView = CalendarView;
    viewDate: Date = new Date();
    updateEvents: UpdateEvent[];
    modalData: ModalData;

    newEvent: UpdateEvent;
    clickDate: Date;
    
    isEpisodeClick = false;
  
    actions: CalendarEventAction[] = [
      {
        label: '<i class="fa fa-fw fa-pencil"></i>',
        a11yLabel: 'Edit',
        onClick: ({ event }: { event: CalendarEvent }): void => {
          this.handleEvent('Edited', event);
        }
      },
      {
        label: '<i class="fa fa-fw fa-times"></i>',
        a11yLabel: 'Delete',
        onClick: ({ event }: { event: CalendarEvent }): void => {
          this.events = this.events.filter(iEvent => iEvent !== event);
          this.handleEvent('Deleted', event);
        }
      }
    ];

    @Output("reloadSchedule") reloadSchedule: EventEmitter<any> = new EventEmitter();
    @Output("updateSchedule") updateSchedule: EventEmitter<any> = new EventEmitter();
    @Output("deleteEpisode") deleteEpisode: EventEmitter<any> = new EventEmitter();
    event: string;
    refresh: Subject<any> = new Subject();
    events$: Observable<Array<CalendarEvent>>;
    activeDayIsOpen: boolean = true;
    isLoading$: BehaviorSubject<boolean> = new BehaviorSubject(false);
    events: CalendarEvent[];
    
    constructor(
      private modal: NgbModal,
      private http: HttpClient,
      private datepipe: DatePipe
    ) {}

    ngOnInit() {
      this.fetchEvents(); 
    }
    
    async fetchEvents(): Promise<void> {
      const getStart: any = {
        month: startOfMonth,
        week: startOfWeek,
        day: startOfDay
      }[this.view];
  
      const getEnd: any = {
        month: endOfMonth,
        week: endOfWeek,
        day: endOfDay
      }[this.view];
      this.isLoading$.next(true);
      this.reloadSchedule.next({
        start: format(getStart(this.viewDate), 'MM-dd-yyyy'),
        end: format(getEnd(this.viewDate), 'MM-dd-yyyy')
      });
    }

    dayClicked({ date, events }: { date: Date; events: CalendarEvent[] }): void {
      if (isSameMonth(date, this.viewDate)) {
        if (
          (isSameDay(this.viewDate, date) && this.activeDayIsOpen === true) ||
          events.length === 0
        ) {
          this.activeDayIsOpen = false;
        } else {
          this.activeDayIsOpen = true;
        }
        this.viewDate = date;
      }
    }
  
    eventTimesChanged({
      event,
      newStart,
      newEnd
    }: CalendarEventTimesChangedEvent): void {
      this.events = this.events.map(iEvent => {
        if (iEvent === event) {
          return {
            ...event,
            start: newStart,
            end: newEnd
          };
        }
        return iEvent;
      });
      this.handleEvent('Dropped or resized', event);
    }

    clearNewEvent() {
      // console.log("clearNewEvent");
      this.newEvent = {
        start: '00:00',
        end: '00:00',
        endDate: null,
        startDate: null,
        scheduleKey: '',
        episodeKey: '',
        title: '',
        description: '',
        time: ''
      }
      // console.log(this.newEvent);
    }

    showAddEvent() {
      this.modal.dismissAll();
      this.modal.open(this.modalAdd, { size: 'lg' });
    }
  
    handleEvent(action: string, event: any): void {
      // console.log(event);
      // console.log("handleEvent");

      if (this.isEpisodeClick) {
        this.isEpisodeClick = false;
        return;
      }
      
      this.clearNewEvent();
      // if(event['events'].length == 0) return;
      let updateEvents = new Array<UpdateEvent>();
      this.clickDate = new Date(event['date']);
      // this.modal.open(this.modalAdd, { size: 'lg' });

      // if (event.hasOwnProperty("events")) {
      //   this.clickDate = new Date(event['date']);
      //   event['events'].forEach(element => {
      //     updateEvents.push({
      //       start: this.datepipe.transform(element.start, 'HH:mm'),
      //       end: this.datepipe.transform(element.end, 'HH:mm'),
      //       endDate: element.end,
      //       startDate: element.start,
      //       scheduleKey: element.scheduleKey,
      //       episodeKey: element.episodeKey,
      //       title: element.title,
      //       description: element.description,
      //       time: element.time,
      //     })
      //   });
      // } else {
        // this.clickDate = new Date(event['start']);
        updateEvents.push({
          start: this.datepipe.transform(event.start, 'HH:mm'),
          end: this.datepipe.transform(event.end, 'HH:mm'),
          endDate: event.end,
          startDate: event.start,
          scheduleKey: event.scheduleKey,
          episodeKey: event.episodeKey,
          title: event.title,
          description: event.description,
          time: event.time,
        })
      // }

      this.modalData = { event, action, updateEvents };

      // if (event.hasOwnProperty("events")) {
      //   if(event['events'].length == 0){
      //     this.modal.open(this.modalAdd, { size: 'lg' });
      //   } else {
      //     this.modal.open(this.modalContent, { size: 'lg' });
      //   };
      // } else {
        this.modal.open(this.modalContent, { size: 'lg' });
      // }
      
    }

    handleHourEvent(action: string, event: any): void {
      // console.log(event)
      // console.log("handleHourEvent");

      this.clearNewEvent();
      this.clickDate = new Date(event['date']);
      this.modal.open(this.modalAdd, { size: 'lg' });

      let updateEvents = new Array<UpdateEvent>();
      this.modalData = { event, action, updateEvents };

      // console.log(this.newEvent);
    }
  
    addEvent(): void {
      // console.log(this.clickDate);
      // console.log(this.newEvent);

      this.newEvent.startDate = new Date(this.clickDate);
      this.newEvent.endDate = new Date(this.clickDate);
      this.newEvent.startDate.setHours(Number(this.newEvent.start.split(':')[0]));
      this.newEvent.startDate.setMinutes(Number(this.newEvent.start.split(':')[1]));
      this.newEvent.endDate.setHours(Number(this.newEvent.end.split(':')[0]));
      this.newEvent.endDate.setMinutes(Number(this.newEvent.end.split(':')[1]));
      this.newEvent.scheduleKey = this.datepipe.transform(this.clickDate, 'yyyyMMdd');
      this.newEvent.episodeKey = this.newEvent.start.replace(':', '');

      this.events = [
        ...this.events,
        {
          title: this.newEvent.title,
          start: this.newEvent.startDate,
          end: this.newEvent.endDate,
        }
      ];

      this.modalData.updateEvents.push(this.newEvent);
      this.updateEvent();

      // this.events = [
      //   ...this.events,
      //   {
      //     title: 'New event',
      //     start: startOfDay(new Date()),
      //     end: endOfDay(new Date()),
      //     color: colors.red,
      //     draggable: true,
      //     resizable: {
      //       beforeStart: true,
      //       afterEnd: true
      //     }
      //   }
      // ];
    }

    episodeClick(action: string, event: any) {
      this.isEpisodeClick = true;
      // console.log(event);

      let updateEvents = new Array<UpdateEvent>();

      this.clickDate = new Date(event['start']);
      updateEvents.push({
        start: this.datepipe.transform(event.start, 'HH:mm'),
        end: this.datepipe.transform(event.end, 'HH:mm'),
        endDate: event.end,
        startDate: event.start,
        scheduleKey: event.scheduleKey,
        episodeKey: event.episodeKey,
        title: event.title,
        description: event.description,
        time: event.time,
      })

      this.modalData = { event, action, updateEvents };
      this.modal.open(this.modalContent, { size: 'lg' });
    }
  
    deleteEvent(eventToDelete: CalendarEvent) {
      if (!confirm("Confirm to delete.")) {
        return;
      }

      this.events = this.events.filter(
        event => 
          !(event['scheduleKey'] === eventToDelete['scheduleKey'] &&
                 event['episodeKey'] === eventToDelete['episodeKey'])
        );

      this.modalData.updateEvents = this.modalData.updateEvents.filter(
        event => 
          !(event['scheduleKey'] === eventToDelete['scheduleKey'] &&
                 event['episodeKey'] === eventToDelete['episodeKey'])
        );

      this.deleteEpisode.emit(eventToDelete);
      this.modal.dismissAll();
    }
  
    setView(view: CalendarView) {
      this.view = view;
    }
  
    closeOpenMonthViewDay() {
      this.activeDayIsOpen = false;
    }
    
    updateEvent(){
      this.updateSchedule.emit(this.modalData);
      this.modal.dismissAll();
      this.fetchEvents();
    }

    display(ev: CalendarEvent[]){
      this.events = Array<CalendarEvent>();
      this.isLoading$.next(false);
      ev.forEach((e, i) => {
        this.events.push(e);
      });
    }
  }
  
import { Component, OnInit } from '@angular/core';
import { ProgramsService } from './programs.service';
import { AngularFirestore } from '@angular/fire/firestore';

const PAGE_SIZE = 10;

@Component({
  selector: 'app-programs',
  templateUrl: './programs.component.html',
  styleUrls: ['./programs.component.scss']
})
export class ProgramsComponent implements OnInit {
  programs: Program[];
  newProgram: Program;
  selectedProgram: Program;
  displayList: Program[];
  page: number;
  maxPage: number;

  displayEpisodesList: Episode[];
  episodePage: number;
  episodeMaxPage: number;

  error: any;
  data: any;

  constructor(
    private programService: ProgramsService,
    private firestore: AngularFirestore,
  ) {
    this.clearNewProgramForm();
    this.programs = [];
    this.changePage(1);
  }

  ngOnInit() {
    this.reloadList();
  }
  uploadImage() {
    console.log(this.newProgram);
  }
  changePage(page) {
    this.page = page;
    this.displayList = this.programs.slice((page-1)*PAGE_SIZE, page*PAGE_SIZE);
  }
  reloadList() {
    this.programs = [];

    this.firestore.collection("programs").get().subscribe(async querySnapshot => {
      let data: any = await querySnapshot.docs.map(doc => ({...doc.data(), id: doc.id}));

      this.programs = data.map((e, i) => {
        return {
          documentID: e.id,
          id: e.program_id,
          title: e.title,
          description: e.description,
          time: e.time,
          startDate: e.start_date.toDate(),
          endDate: e.end_date.toDate(),
        }
      });

      this.maxPage = Math.ceil(this.programs.length/PAGE_SIZE) || 1;
      this.changePage(1);
    });
  }

  getStatus(program: Program): string {
    let currentDate = new Date();
    let status = '';
    if(currentDate >= program.startDate && currentDate <= program.endDate) {
      status = "ACTIVE";
    } else if(currentDate < program.startDate) {
      status = 'PENDING';
    } else if(currentDate > program.endDate) {
      status = 'INACIVE';
    }

    return status;
  }

  updateProgram() {
    this.firestore.doc(`programs/${this.selectedProgram.documentID}`).update({
      program_id: this.selectedProgram.id,
      title: this.selectedProgram.title,
      description: this.selectedProgram.title,
      time: this.selectedProgram.time,
      start_date: new Date(this.selectedProgram.startDate),
      end_date: new Date(this.selectedProgram.endDate),
    }).then(querySnapshot => {
      this.reloadList();
    })
  }

  createNewProgram() {
    this.firestore.collection('programs').add({
      program_id: this.newProgram.id,
      title: this.newProgram.description,
      description: this.newProgram.title,
      time: this.newProgram.time,
      start_date: new Date(this.newProgram.startDate),
      end_date: new Date(this.newProgram.endDate),
    }).then(querySnapshot => {
      this.reloadList();
    })
  }

  deleteProgram() {
    this.firestore.doc(`programs/${this.selectedProgram.documentID}`).delete()
    .then(querySnapshot => {
      this.reloadList();
    })
  }

  clearNewProgramForm() {
    this.newProgram = {
      documentID: null,
      id: null,
      title: null,
      description: null,
      time: null,
      startDate: null,
      endDate: null,
    }
  }

  setTarget(program) {
    this.selectedProgram = { ...program };
    // this.episodeMaxPage = Math.ceil(program.episodes.length/PAGE_SIZE) || 1;
    // this.changeEpisodePage(1);
  }
  // changeEpisodePage(page) {
  //   this.episodePage = page;
  //   this.displayEpisodesList = this.selectedProgram.episodes.slice((page-1)*PAGE_SIZE, page*PAGE_SIZE);
  // }
  // setEpisodeTarget(episode) {

  // }
}

interface Episode {
  index: number;
  id: string;
  startDate: string;
  endDate: string;
  description: string;
}

interface Program {
  documentID: string;
  id: string;
  title: string;
  description: string;
  time: string;
  startDate: Date;
  endDate: Date;
}
<div class="hold-transition login-page">
  <div class="login-box">
    <div class="login-logo">
      <a href="../../index2.html"><b>Sbay</b>IT</a>
    </div>
    <!-- /.login-logo -->
    <div class="card">
      <div class="card-body login-card-body">
        <p class="login-box-msg">Sign in to start your session</p>

        <form #form="ngForm" (ngSubmit)="onLogin()" method="post">
          <span class="text-red" *ngIf="error">
            {{ error.email }}
          </span>
          <div class="input-group mb-3">
            <input [(ngModel)]="email"
                   name="email"
                   type="text"
                   class="form-control"
                   placeholder="Email">

            <div class="input-group-append">
              <div class="input-group-text">
                <span class="fas fa-envelope"></span>
              </div>
            </div>
          </div>
          <span class="text-red" *ngIf="error">
            {{ error.password }}
          </span>
          <div class="input-group mb-3">
            <input [(ngModel)]="password"
                   name="password"
                   type="password"
                   class="form-control"
                   placeholder="Password">

            <div class="input-group-append">
              <div class="input-group-text">
                <span class="fas fa-lock"></span>
              </div>
            </div>
          </div>

          <div class="row">
            <div class="col-8">
              <div class="icheck-primary">
                <input type="checkbox" id="remember">
                <label for="remember">
                  Remember Me
                </label>
              </div>
            </div>
            <!-- /.col -->
            <div class="col-4">
              <button type="submit" class="btn btn-primary btn-block">Sign In</button>
            </div>
            <!-- /.col -->
          </div>
        </form>
        <span class="text-red" *ngIf="error">
          {{ error.message }}
        </span>
      </div>
      <!-- /.login-card-body -->
    </div>
  </div>
</div>

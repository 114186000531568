import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';

import { AppComponent } from './app.component';
import { AppRouting } from './app.routing';
import { Configuration } from '../config/app.constant';
import { MainComponent } from './main/main.component';
import { DashboardComponent } from './dashboard/dashboard.component';
import { AnnouncementsComponent } from './announcements/announcements.component';
import { ProgramsComponent } from './programs/programs.component';
import { ManageAdminComponent } from './manage-admin/manage-admin.component';
import { NotFoundComponent } from './not-found/not-found.component';
import { FooterComponent } from './layout/footer/footer.component';
import { HeaderComponent } from './layout/header/header.component';
import { LoginComponent } from './authen/login/login.component';
import { SidebarComponent } from './layout/sidebar/sidebar.component';
import { AuthenService } from './authen/authen.service';
import { AuthenInterceptor } from './authen/authen.interceptor';
import { ImgFallbackDirective } from './img-fallback.directive';
import { FileInputValueAccessor } from "./file-input.accessor";
import {FormsModule} from '@angular/forms';
import {AuthenGuard} from './guards/authen.guard';

import { environment } from '../environments/environment';
import { AngularFireModule } from '@angular/fire';
import { AngularFireDatabaseModule } from '@angular/fire/database';
import { AngularFirestoreModule } from '@angular/fire/firestore';
import { AngularFireStorageModule } from '@angular/fire/storage';
import { AngularFireAuthModule } from '@angular/fire/auth';
import { ImportschedulesComponent } from './importschedules/importschedules.component';
import { from } from 'rxjs';
import { NgbModule, NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { AlertModalContentModule } from './components/modal/alert-modal.module'
import { UsersComponent } from './users/users.component';

import { CalendarScheduleModule } from './components/schedule/schedule.module';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { DatePipe } from '@angular/common';
import { SchedulesComponent } from './schedules/schedules.component';
import { AboutUsComponent } from './about-us/about-us.component';

@NgModule({
  declarations: [
    AppComponent,
    MainComponent,
    DashboardComponent,
    AnnouncementsComponent,
    ProgramsComponent,
    ManageAdminComponent,
    NotFoundComponent,
    FooterComponent,
    HeaderComponent,
    LoginComponent,
    SidebarComponent,
    ImgFallbackDirective,
    FileInputValueAccessor,
    ImportschedulesComponent,
    UsersComponent,
    AnnouncementsComponent,
    SchedulesComponent,
    AboutUsComponent,
  ],
  imports: [
    NgbModule,
    BrowserModule,
    HttpClientModule,
    AppRouting,
    FormsModule,
    AngularFireModule.initializeApp(environment.firebase),
    AngularFireDatabaseModule,
    AngularFirestoreModule,
    AngularFireStorageModule,
    AngularFireAuthModule,
    AlertModalContentModule,
    CalendarScheduleModule,
    BrowserAnimationsModule
  ],
  exports: [

  ],
  providers: [
    NgbActiveModal,
    Configuration,
    AuthenService,
    AuthenGuard,
    {
      provide : HTTP_INTERCEPTORS,
      useClass: AuthenInterceptor,
      multi   : true,
    },
    DatePipe
  ],
  bootstrap: [
    AppComponent
  ],
})
export class AppModule { }

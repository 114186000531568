import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Configuration } from '../../config/app.constant';
import { AngularFirestore, AngularFirestoreCollection } from '@angular/fire/firestore';
import {
    isSameMonth,
    isSameDay,
    startOfMonth,
    endOfMonth,
    startOfWeek,
    endOfWeek,
    startOfDay,
    endOfDay,
    format,
    subDays,
    addDays,
    addHours
} from 'date-fns';
import {
CalendarEvent,
CalendarEventAction,
CalendarEventTimesChangedEvent,
CalendarView,
} from 'angular-calendar';
import { colors } from '../components/schedule/util/colors'
import { Observable} from 'rxjs/Rx'
import { Schedule } from '../models/schedule';
import { Episode } from '../models/episode';
import { map, switchMap, combineLatest } from 'rxjs/operators'
import { uniq, flatten } from 'lodash'
import { of, Scheduler } from 'rxjs';
import { ModalData } from '../components/schedule/models/modalData';
import { UpdateEvent } from '../components/schedule/models/updateEvent';
import { DatePipe } from '@angular/common';
import * as moment from 'moment';


@Injectable({
  providedIn: 'root'
})
export class SchedulesService {

    episodes: Array<any>;
    schedules: Schedule[] = [];
    schedule: Schedule;

    private actionUrl: string;
    private calendarEvent: CalendarEvent[];

  constructor(
    private http: HttpClient,
    private configuration: Configuration,
    private firestore: AngularFirestore,
    private datepipe: DatePipe,
  ) {}
  
  item: any;
  result: any[] = new Array<any>();
  
  
  async getSchedules(obj: any): Promise<any[]> {
    let dates: Array<string> = this.getBetweenDate(obj);
    this.result = Array<any>();
     
    for(const date of dates){ 
      await this.getEpisodes(date);
      this.episodes.forEach((t, i) => {
        let startYear: number = +date.substring(0, 4);
        let startMonth: number = +date.substring(4, 6);
        let startDay: number = +date.substring(6, 8);
        this.result.push({
          start: t.start_date.toDate(),
          end: t.end_date.toDate(),
          title: t.title,
          description: t.description,
          time: t.time,
          color: colors.yellow,
          // resizable: {
          //   beforeStart: true,
          //   afterEnd: true
          // },
          // draggable: true,
          scheduleKey: date,
          episodeKey: t.id
        });
      });
    }
    /*await this.getX();
    for(const item of this.item){ 
      await this.getY(item.id).then(()=>{
        this.episodes.forEach((t, i) => {
          let startYear: number = +item.id.substring(0, 4);
          let startMonth: number = +item.id.substring(4, 6);
          let startDay: number = +item.id.substring(6, 8);
          //let startDate = new Date(this.datepipe.transform(t.start_date.toDate(), 'yyyy-MM-dd') + 'T' + t.id.substring(0,2) + ':' + t.id.substring(2,4));
          //let endDate = new Date(this.datepipe.transform(t.end_date.toDate(), 'yyyy-MM-dd') + 'T' + t.id.substring(0,2) + ':' + t.id.substring(2,4));
          this.result.push({
            start: t.start_date.toDate(),
            end: t.end_date.toDate(),
            title: t.title,
            color: colors.yellow,
            resizable: {
              beforeStart: true,
              afterEnd: true
            },
            draggable: true,
            scheduleKey: item.id,
            episodeKey: t.id
          });
        });
      });
    }*/
    return this.result;
  }
  
  async getEpisodes(date: string){
    await this.firestore.collection(`schedules/${date}/episodes`).get().toPromise().then(r => {
      let data = r.docs.sort().map(doc => ({...doc.data(), id: doc.id}));
      this.episodes = Array<any>();
      if(data.length > 0){
        this.episodes = data; 
      }
    });
  }

  async getY(docId){
    await this.firestore.collection('schedules').doc(docId).collection('episodes').get().toPromise().then(r => {
      this.episodes = r.docs.map(doc => ({...doc.data(), id: doc.id}));
    });
  }

  async getX(){
    await this.firestore.collection('schedules').get().toPromise().then(r => {
      this.item = r.docs.map(doc => ({...doc.data(), id: doc.id}));
    });
  }

  updateSchedule(data: ModalData){
    
    let date = data.event["events"]
    for(const item of data.updateEvents){ 
      let startDate = new Date(this.datepipe.transform(item.startDate, 'yyyy-MM-dd') + 'T' + item.start);
      let endDate = new Date(this.datepipe.transform(item.endDate, 'yyyy-MM-dd') + 'T' + item.end);
      console.log(startDate);
      this.firestore.doc(`schedules/${item.scheduleKey}/episodes/${item.episodeKey}`).set({
        title: item.title,
        description: item.description,
        time: item.time,
        start_date: startDate,
        end_date: endDate 
      }).catch(error => {console.log(error)});
    }
  }

  deleteEpisode(eventToDelete: CalendarEvent){
    this.firestore.collection('schedules').doc(eventToDelete['scheduleKey'])
    .collection('episodes').doc(eventToDelete['episodeKey']).delete()
    .then(() => console.log("success"))
    .catch((err) => console.log(err));
  }

  getBetweenDate(obj: any): Array<string> {
    // obj.start = "04-02-2020";
    // obj.end = "04-03-2020";
    let a = moment(new Date(obj.start));
    let b = moment(new Date(obj.end));
    let tempDate = a;
    let daysCount = b.diff(a, 'days');

    // console.log('daysCount',daysCount);

    let dates:Array<string> = [];
    dates.push(this.datepipe.transform(new Date(obj.start), 'yyyyMMdd'));
    for(let i= 1;i<daysCount ;i++){
      tempDate =tempDate.add(1, 'day');
      dates.push(this.datepipe.transform(tempDate.toDate(), 'yyyyMMdd'));
    }
    dates.push(this.datepipe.transform(new Date(obj.end), 'yyyyMMdd'));

    // console.log(dates);
    return dates;
  }
  
}

<!-- Content Wrapper. Contains page content -->
<div class="content-wrapper">
  <!-- Content Header (Page header) -->
  <div class="content-header">
    <div class="container-fluid">
      <div class="row mb-2">
        <div class="col-sm-6">
          <h1 class="m-0 text-dark">Import Schedules</h1>
        </div><!-- /.col -->
        <div class="col-sm-6">
          <ol class="breadcrumb float-sm-right">
            <li class="breadcrumb-item active">Import Schedules</li>
          </ol>
        </div><!-- /.col -->
      </div><!-- /.row -->
    </div><!-- /.container-fluid -->
  </div>
  <!-- /.content-header -->

  <!-- Main content -->
  <section class="content">
    <div class="container-fluid">
      <!-- Small boxes (Stat box) -->
      <!-- /.row -->
      <!-- Main row -->
      <div class="row">
        <!-- Left col -->
        <section class="col-lg-12 connectedSortable">
          <!-- Custom tabs (Charts with tabs)-->
          <div class="card">
            <div class="card-body">
              <div class="tab-content p-0">
                <div class="form-group">
                  <label for="image">Excel File</label>
                  <div class="input-group mb-3">
                    <div class="custom-file">
                      <input [(ngModel)]="excelFile" [ngModelOptions]="{standalone: true}" [disabled]="uploading" observeFiles type="file" accept=".xls, .xlsx" class="custom-file-input" id="fileName" (change)="incomingfile($event)">
                      <label class="custom-file-label" for="fileName">{{excelFile ? excelFile.name : "Choose File"}}</label>
                    </div>
                    <div class="input-group-append">
                      <span class="input-group-text" id="" data-toggle="modal" data-target="#previewImportSchedules" (click)="priviewImportSchedules()">Preview</span>
                    </div>
                  </div>
                  <p>{{message}}</p>
                </div>
              </div>
              <a href="https://firebasestorage.googleapis.com/v0/b/tc-2020-002-3b983.appspot.com/o/example%2Fsample_epg_templage2.xlsx?alt=media&token=37aac218-e34a-4826-8193-03935aadc6c2">Example</a>
            </div><!-- /.card-body -->
          </div>
          <!-- /.card -->
        </section>
      </div>
      <!-- /.row (main row) -->
    </div><!-- /.container-fluid -->
  </section>
  <!-- /.content -->
</div>
<!-- /.content-wrapper -->

<!-- Modal -->
<div class="modal fade" id="previewImportSchedules" tabindex="-1" role="dialog" aria-labelledby="previewImportSchedulesLabel" aria-hidden="true">
  <div class="modal-dialog modal-lg" role="document">
    <form class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" id="previewImportSchedulesLabel">Preview Import Schedules</h5>
        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <table class="table table-hover program-table">
          <thead>
            <tr>
              <th scope="col">#</th>
              <th scope="col">Program Name</th>
              <th scope="col">Epg Start</th>
              <th scope="col">Epg Stop</th>
            </tr>
          </thead>
          <tbody>
            <tr *ngFor="let data of displayList" data-toggle="modal">
              <th scope="row">{{data.index}}</th>
              <td>{{data.programName}}</td>
              <td>{{data.epgStart}}</td>
              <td>{{data.epgStop}}</td>
            </tr>
          </tbody>
        </table>
        <div class="d-flex w-100 justify-content-end">
          <nav aria-label="Page navigation">
            <ul class="pagination">
              <li class="page-item" [ngClass]="{disabled: page === 1}">
                <a class="page-link" (click)="changePage(page-1)" aria-label="Previous">
                  <span aria-hidden="true">&laquo;</span>
                  <span class="sr-only">Previous</span>
                </a>
              </li>
              <li class="page-item" *ngIf="page === maxPage && maxPage >= 3"><a class="page-link" (click)="changePage(page-2)">{{page - 2}}</a></li>
              <li class="page-item" *ngIf="page > 1 && maxPage >= 2"><a class="page-link" (click)="changePage(page-1)">{{page - 1}}</a></li>
              <li class="page-item active"><a class="page-link" (click)="changePage(page)">{{page}}</a></li>
              <li class="page-item" *ngIf="page < maxPage && maxPage >= 2"><a class="page-link" (click)="changePage(page + 1)">{{page + 1}}</a></li>
              <li class="page-item" *ngIf="page === 1 && maxPage >= 3"><a class="page-link" (click)="changePage(page + 2)">{{page + 2}}</a></li>
              <li class="page-item" [ngClass]="{disabled: page === maxPage}">
                <a class="page-link" (click)="changePage(page + 1)" aria-label="Next">
                  <span aria-hidden="true">&raquo;</span>
                  <span class="sr-only">Next</span>
                </a>
              </li>
            </ul>
          </nav>
        </div>
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-secondary" data-dismiss="modal">Close</button>
        <button type="submit" class="btn btn-primary" (click)="importSchedules()" data-dismiss="modal">Import</button>
      </div>
    </form>
  </div>
</div>

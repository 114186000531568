import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Configuration } from '../../config/app.constant';

@Injectable({
  providedIn: 'root'
})
export class ProgramsService {
  private actionUrl: string;

  constructor(
    private http: HttpClient,
    private configuration: Configuration
  ) {}

  public getPrograms() {
    this.actionUrl = this.configuration.ServerWithApiUrl + 'programs';
    return this.http.get(this.actionUrl);
  }
}

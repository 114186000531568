import { Component, OnInit } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import { AngularFireAuth } from '@angular/fire/auth';

const PAGE_SIZE = 10;

@Component({
  selector: 'app-users',
  templateUrl: './users.component.html',
  styleUrls: ['./users.component.scss']
})
export class UsersComponent implements OnInit {
  users: User[];
  newUser: User;
  selectedUser: User;
  displayList: User[];
  page: number;
  maxPage: number;
  error: any;
  data: any;
  statusList: string[] = ['active', 'inactive'];
  constructor(
    private fireAuth: AngularFireAuth,
    private firestore: AngularFirestore, ) {
    this.users = [];
  }

  ngOnInit(): void {
    this.getUsers();
    this.clearNewUserForm();
  }

  getUsers() {
    this.firestore.collection('users').get().subscribe(async querySnapshot => {
      const data: any = await querySnapshot.docs.map(doc => ({...doc.data(), id: doc.id}));
      this.users = data.map((e, i) => {
        console.log(e);
        return {
          documentID: e.id,
          email: e.email,
          status: e.status
        };
      });

      this.maxPage = Math.ceil(this.users.length / PAGE_SIZE) || 1;
      this.changePage(1);
    });
  }

  changePage(page: number) {
    this.page = page;
    this.displayList = this.users.slice((page - 1) * PAGE_SIZE, page * PAGE_SIZE);
  }

  updateUser() {
    this.firestore.doc(`users/${this.selectedUser.documentID}`).update({
      email: this.selectedUser.email.trim(),
      status: this.selectedUser.status,
    }).then(querySnapshot => {
      this.getUsers();
    });
  }

  createNewUser() {
    this.firestore.collection('users').add({
      email: this.newUser.email.trim(),
      status: 'active',
    }).then(querySnapshot => {
      this.getUsers();
    });
  }

  deleteUser() {
    this.firestore.doc(`users/${this.selectedUser.documentID}`).delete()
    .then(querySnapshot => {
      this.getUsers();
    });
  }

  clearNewUserForm() {
    this.newUser = {
      documentID: null,
      email: null,
      status: null,
    };
  }

  setTarget(user) {
    this.selectedUser = { ...user };
  }

}

interface User {
  documentID: string;
  email: string;
  status: string;
}



<!-- Content Wrapper. Contains page content -->
<div class="content-wrapper">
  <!-- Content Header (Page header) -->
  <div class="content-header">
    <div class="container-fluid">
      <div class="row mb-2">
        <div class="d-flex justify-content-between col-12">
          <h1 class="m-0 text-dark">Programs</h1>
          <button class="btn btn-primary" data-toggle="modal" data-target="#newProgram" (click)="clearNewProgramForm()">
            + New Program
          </button>
        </div>
      </div><!-- /.row -->
    </div><!-- /.container-fluid -->
  </div>
  <!-- /.content-header -->

  <!-- Main content -->
  <section class="content">
    <div class="container-fluid">
      <!-- Small boxes (Stat box) -->

      <div class="row">
        <div class="col-12">
          <div class="card">
            <div class="card-body">
              <div class="table-responsive">

                <table class="table table-hover program-table">
                  <thead>
                    <tr>
                      <th scope="col">#</th>
                      <th scope="col">Title</th>
                      <th scope="col">Start</th>
                      <th scope="col">End</th>
                      <th scope="col">Status</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr *ngFor="let program of displayList"
                      data-toggle="modal" data-target="#updateProgram"
                      (click)="setTarget(program)"
                    >
                      <td scope="row">{{program.id}}</td>
                      <td>{{program.title}}</td>
                      <td>{{program.startDate | date: 'dd/MM/yyyy'}}</td>
                      <td>{{program.endDate | date: 'dd/MM/yyyy'}}</td>
                      <td>{{getStatus(program)}}</td>
                    </tr>
                  </tbody>
                </table>

                <div class="d-flex w-100 justify-content-end">
                  <nav aria-label="Page navigation">
                    <ul class="pagination">
                      <li class="page-item" [ngClass]="{disabled: page === 1}">
                        <a class="page-link" (click)="changePage(page-1)" aria-label="Previous">
                          <span aria-hidden="true">&laquo;</span>
                          <span class="sr-only">Previous</span>
                        </a>
                      </li>
                      <li class="page-item" *ngIf="page === maxPage && maxPage >= 3"><a class="page-link" (click)="changePage(page-2)">{{page - 2}}</a></li>
                      <li class="page-item" *ngIf="page > 1 && maxPage >= 2"><a class="page-link" (click)="changePage(page-1)">{{page - 1}}</a></li>
                      <li class="page-item active"><a class="page-link" (click)="changePage(page)">{{page}}</a></li>
                      <li class="page-item" *ngIf="page < maxPage && maxPage >= 2"><a class="page-link" (click)="changePage(page+1)">{{page + 1}}</a></li>
                      <li class="page-item" *ngIf="page === 1 && maxPage >= 3"><a class="page-link" (click)="changePage(page+2)">{{page + 2}}</a></li>
                      <li class="page-item" [ngClass]="{disabled: page === maxPage}">
                        <a class="page-link" (click)="changePage(page+1)" aria-label="Next">
                          <span aria-hidden="true">&raquo;</span>
                          <span class="sr-only">Next</span>
                        </a>
                      </li>
                    </ul>
                  </nav>
                </div>

              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</div>

<!-- Modal -->
<div class="modal fade" id="newProgram" tabindex="-1" role="dialog" aria-labelledby="newProgramLabel" aria-hidden="true">
  <div class="modal-dialog" role="document">
    <form class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" id="newProgramLabel">Create New Program</h5>
        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <div class="form-group">
          <label for="id">Program ID</label>
          <input [(ngModel)]="newProgram.id" [ngModelOptions]="{standalone: true}" class="form-control" id="id">
        </div>
        <div class="form-group">
          <label for="title">Title</label>
          <input [(ngModel)]="newProgram.title" [ngModelOptions]="{standalone: true}" class="form-control" id="title">
        </div>
        <div class="form-group">
          <label for="description">Description</label>
          <input [(ngModel)]="newProgram.description" [ngModelOptions]="{standalone: true}" class="form-control" id="description">
        </div>
        <div class="form-group">
          <label for="time">Time</label>
          <input [(ngModel)]="newProgram.time" [ngModelOptions]="{standalone: true}" class="form-control" id="time">
        </div>
        <div class="form-group">
          <label for="startDate">Start Date</label>
          <input (input)="newProgram.startDate = $event.target.value" type="date" [value]="newProgram.startDate | date:'yyyy-MM-dd'" class="form-control" id="startDate">
        </div>
        <div class="form-group">
          <label for="name">End Date</label>
          <input (input)="newProgram.endDate = $event.target.value" type="date" [value]="newProgram.endDate | date:'yyyy-MM-dd'" class="form-control" id="endDate">
        </div>
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-secondary" data-dismiss="modal">Close</button>
        <button type="submit" class="btn btn-primary" data-dismiss="modal"
        [disabled]="(newProgram.id == '' || newProgram.title == '' || newProgram.description == '' || newProgram.time == '' || newProgram.startDate == null || newProgram.endDate == null)"
        (click)="createNewProgram()">Create</button>
      </div>
    </form>
  </div>
</div>

<!-- Modal -->
<div class="modal fade" id="updateProgram" tabindex="-1" role="dialog" aria-labelledby="updateProgramLabel" aria-hidden="true">
  <div class="modal-dialog modal-lg" role="document" *ngIf="selectedProgram">
    <form class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" id="updateProgramLabel">Program Detail</h5>
        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>

      <div class="modal-body">
        <div class="form-group">
          <label for="id">Program ID</label>
          <input [(ngModel)]="selectedProgram.id" [ngModelOptions]="{standalone: true}" class="form-control" id="id">
        </div>
        <div class="form-group">
          <label for="title">Title</label>
          <input [(ngModel)]="selectedProgram.title" [ngModelOptions]="{standalone: true}" class="form-control" id="title">
        </div>
        <div class="form-group">
          <label for="description">Description</label>
          <input [(ngModel)]="selectedProgram.description" [ngModelOptions]="{standalone: true}" class="form-control" id="description">
        </div>
        <div class="form-group">
          <label for="time">Time</label>
          <input [(ngModel)]="selectedProgram.time" [ngModelOptions]="{standalone: true}" class="form-control" id="time">
        </div>
        <div class="form-group">
          <label for="startDate">Start Date</label>
          <input (input)="selectedProgram.startDate = $event.target.value" type="date" [value]="selectedProgram.startDate | date:'yyyy-MM-dd'" class="form-control" id="startDate">
        </div>
        <div class="form-group">
          <label for="name">End Date</label>
          <input (input)="selectedProgram.endDate = $event.target.value" type="date" [value]="selectedProgram.endDate | date:'yyyy-MM-dd'" class="form-control" id="endDate">
        </div>
      </div>

      <div class="modal-footer">
        <button type="button" class="btn btn-danger" data-dismiss="modal" (click)="deleteProgram()">Delete</button>
        <button type="button" class="btn btn-secondary" data-dismiss="modal">Close</button>
        <button type="submit" class="btn btn-primary" data-dismiss="modal"
        [disabled]="(selectedProgram.id == '' || selectedProgram.title == '' || selectedProgram.description == '' || selectedProgram.time == '' || selectedProgram.startDate == null || selectedProgram.endDate == null)"
        (click)="updateProgram()">Update</button>
      </div>
    </form>
  </div>
</div>

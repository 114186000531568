import { Component, OnInit } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import { AngularFireStorage, AngularFireUploadTask } from '@angular/fire/storage';
import { finalize } from 'rxjs/operators';

@Component({
  selector: 'app-about-us',
  templateUrl: './about-us.component.html',
  styleUrls: ['./about-us.component.scss']
})
export class AboutUsComponent implements OnInit {

  address: string;
  bkg: string;
  body: string;
  email: string;
  logo: string;
  phone: string;

  image: any;
  uploadTask: AngularFireUploadTask;
  uploading: boolean;

  constructor(
    private firestore: AngularFirestore,
    private storage: AngularFireStorage
  ) { }

  ngOnInit(): void {
    this.reload();
  }

  reload() {
    // /about_us/settings
    this.firestore.collection('about_us').doc('settings').get().subscribe(snapshot => {
      this.address = snapshot.data().address;
      this.logo = snapshot.data().logo;
      this.bkg = snapshot.data().bkg; // 0xFFE3F2FD
      this.body = snapshot.data().body;
      this.phone = snapshot.data().phone;
      this.email = snapshot.data().email;
    });
  }

  uploadImage() {
    if (!this.image || this.uploading) { return; }

    const image: any = this.image;
    const fileType = image.type.split('/')[1];
    const filename = `${String(new Date().getTime())}.${fileType}`;
    const uploadTo = `/images/settings/${filename}`;

    const ref = this.storage.ref(uploadTo);
    this.uploadTask = this.storage.upload(uploadTo, image);
    this.uploading = true;
    this.uploadTask.percentageChanges();
    this.uploadTask.snapshotChanges().pipe(
      finalize(async () =>  {
        const downloadURL = await ref.getDownloadURL().toPromise();
        this.uploading = false;
        this.logo = downloadURL;
        console.log('uploadImage', this.logo);
      })).subscribe();
  }

  updateAboutUs() {

    this.firestore.collection('about_us').doc('settings').update({
      address : this.address,
      logo : this.logo,
      bkg : this.bkg,
      body : this.body,
      phone : this.phone,
      email : this.email
    })
    .then(querySnapshot => {
      this.reload();
      alert('update successful');
    });
  }
}

import { Component, OnInit } from '@angular/core';
import {Router} from '@angular/router';

import { AngularFireAuth } from "@angular/fire/auth";

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {

  constructor(private route: Router, private fireAuth: AngularFireAuth) { }

  ngOnInit() {
  }

  onLogout() {
    this.fireAuth.auth.signOut();
    localStorage.removeItem('tc_admin_auth_info');
    this.route.navigate(['login']);
  }
}

import { Component, OnInit } from '@angular/core';
import { AuthenService } from '../authen.service';
import { Router } from '@angular/router';

import { AngularFirestore } from '@angular/fire/firestore';
import { AngularFireStorage, AngularFireUploadTask } from '@angular/fire/storage';
import { AngularFireAuth } from "@angular/fire/auth";
import { Observable } from 'rxjs';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { AlertModalContent } from 'src/app/components/modal/alert-modal.component';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {
  email: string;
  password: string;
  error: any;
  data: any;
  users: any;

  constructor(
    private authen: AuthenService,
    private router: Router,
    private firestore: AngularFirestore,
    private modalService: NgbModal,
    private fireAuth: AngularFireAuth
  ) {
  }

  ngOnInit() {
  }

  onLogin() {
    // console.log('login');
    // 1. Verify user on users collection
    this.firestore.collection('users').get().subscribe(async querySnapshot => {
      const data: any = await querySnapshot.docs.map(doc => ({ ...doc.data(), id: doc.id }));
      let found = false;
      this.users = data.map((e, i) => {
        if (e.email.trim() === this.email.trim() && e.status === 'active') {
          found = true;
          this.fireAuth
          .auth
          .signInWithEmailAndPassword(this.email.trim(), this.password)
          .then(res => {
            localStorage.setItem('tc_admin_auth_info', JSON.stringify({ email: this.email }));
            this.router.navigate(['/announcements']);
          })
          .catch(err => {
            alert("Email or Password incorrect.");
            console.log('Something is wrong:', err.message);
          });
        } else if (e.email.trim() === this.email.trim() && e.status === 'inactive'){
          found = true;
          alert("Your are inactive.");
          console.log("Your are inactive");
        }
      });

      if (!found) {
        alert("Login not permit.");
        console.log("Not permit to login");
      }

    });
  }

  isset(array, type) {
    return array.hasOwnProperty(type) !== undefined;
  }

  alertModal() {
    const modalRef = this.modalService.open(AlertModalContent);
    modalRef.componentInstance.id = 'xxxx';
    modalRef.componentInstance.message = 'login';
    modalRef.result.then((result) => {
      if (result) {
        console.log(result);
      }
    });
  }
}

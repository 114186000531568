import { Component, OnInit } from '@angular/core';

import { AngularFirestore } from '@angular/fire/firestore';
import { AngularFireStorage, AngularFireUploadTask } from '@angular/fire/storage';
import { Observable } from 'rxjs';
import { map, finalize, tap } from 'rxjs/operators';
import { analytics } from 'firebase';
import * as XLSX from 'xlsx';

const PAGE_SIZE = 10;

interface ImportData {
  index: number;
  programName: string;
  epgStart: string;
  epgStop: string;
  desciption: string;
  time: string;
}

@Component({
  selector: 'app-importschedules',
  templateUrl: './importschedules.component.html',
  styleUrls: ['./importschedules.component.scss']
})
export class ImportschedulesComponent implements OnInit {
  excelFile: any;
  uploading: boolean;
  file: File;
  fileContent: any;
  data: any;
  displayList: ImportData[];
  programs: ImportData[];
  excelData: any;
  page: number;
  maxPage: number;
  message: string;

  constructor(
    private firestore: AngularFirestore,
    private storage: AngularFireStorage,
  ) {
    this.displayList = [];
    this.programs = [];
  }

  ngOnInit(): void {

  }

  incomingfile(evt: any) {
    this.displayList = [];
    this.programs = [];
    /* wire up file reader */
    const target: DataTransfer = (evt.target) as DataTransfer;
    if (target.files.length !== 1) { throw new Error('Cannot use multiple files'); }
    const reader: FileReader = new FileReader();
    reader.onload = (e: any) => {
      /* read workbook */
      const bstr: string = e.target.result;
      const wb: XLSX.WorkBook = XLSX.read(bstr, { type: 'binary' });

      /* grab first sheet */
      const wsname: string = wb.SheetNames[0];
      const ws: XLSX.WorkSheet = wb.Sheets[wsname];

      /* save data */
      this.excelData = XLSX.utils.sheet_to_json(ws, { raw: false });
      let count = 0;
      this.excelData.forEach(element => {
        count++;
        const item: ImportData = {
          index: count,
          programName: element['Program Name'],
          epgStart: element['Epg Start'],
          epgStop: element['Epg Stop'],
          desciption: element['Description'],
          time: element['Time']
        };
        this.displayList.push(item);
        this.programs.push(item);
      });
    };
    reader.readAsBinaryString(target.files[0]);
  }

  importSchedules() {
    this.excelFile = null;

    let hasError = false;
    let errorMessage = '';

    // console.log(this.programs);

    this.programs.forEach(element => {
      // console.log(element);
      const scheduleId =  element.epgStart.substr(0, 10).split('-').join('');
      const episodeId = element.epgStart.substr(11, 5).split(':').join('');
      
      let end_date = new Date(element.epgStop);
      if (end_date.getHours() == 0) {
        end_date = new Date(end_date.getTime() - 1);
        // console.log(end_date);
       }

      this.firestore.collection('schedules').doc(scheduleId).set({
        date: new Date(element.epgStart.substr(0, 10))
      });
      
      this.firestore.collection('schedules/' + scheduleId + '/episodes').doc(episodeId).set({
        id: episodeId,
        description: element.desciption ? element.desciption : null ,
        end_date: end_date,
        program_id: null,
        start_date: new Date(element.epgStart),
        time: element.time ? element.time : null,
        title: element.programName
      }).then(querySnapshot => {
        // console.log("inserting " + element.index + " / " + element.programName);
        this.message = "Inserting (" + element.index + "/" + this.programs.length + ") " + element.epgStart + " " +element.programName;

        if (element.index == this.programs.length) {
          if (!hasError) {
            this.message = 'Done';
            setTimeout(() => {
              alert("import successful.");
              this.message = '';
            }, 1000);
            console.log("import finish");
          } else {
            alert("import failed. " + errorMessage);
          }
        }
      }).catch((err) => {
        hasError = true;
        errorMessage = err;
        console.log(err);
      });
    });
  }

  priviewImportSchedules(): void {
    this.maxPage = Math.ceil(this.programs.length / PAGE_SIZE) || 1;
    this.changePage(1);
  }

  changePage(page: number) {
    this.page = page;
    this.displayList = this.programs.slice((page - 1) * PAGE_SIZE, page * PAGE_SIZE);
  }
}

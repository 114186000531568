import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { FlatpickrModule } from 'angularx-flatpickr';
import { CalendarModule, DateAdapter } from 'angular-calendar';
import { adapterFactory } from 'angular-calendar/date-adapters/date-fns';
import { NgbModalModule } from '@ng-bootstrap/ng-bootstrap';
import { CalendarScheduleComponent } from './schedule.component';
import { CarlendartilModule } from './util/module'

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    NgbModalModule,
    FlatpickrModule.forRoot(),
    CalendarModule.forRoot({
      provide: DateAdapter,
      useFactory: adapterFactory
    }),
    CarlendartilModule
  ],
  declarations: [CalendarScheduleComponent],
  exports: [CalendarScheduleComponent],
  providers: [
    CalendarScheduleComponent
  ]
})
export class CalendarScheduleModule {}

import { Component, OnInit } from '@angular/core';
import { ManageAdminService } from './manage-admin.service';

import { AngularFirestore } from '@angular/fire/firestore';
import { AngularFireStorage, AngularFireUploadTask } from '@angular/fire/storage';
import { Observable } from 'rxjs';

interface Admin {
  index: number;
  id: string;
  username: string;
  password: string;
  name: string;
  role: string;
  status: string;
}

@Component({
  selector: 'app-manage-admin',
  templateUrl: './manage-admin.component.html',
  styleUrls: ['./manage-admin.component.scss']
})
export class ManageAdminComponent implements OnInit {
  admins: Admin[];
  newAdmin: Admin;
  selectedAdmin: Admin;
  error: any;
  data: any;

  constructor(
    private manageAdmin: ManageAdminService,
    private firestore: AngularFirestore,
  ) {
    this.clearNewAdminForm();
  }

  ngOnInit() {
    this.reloadList();
  }
  reloadList() {
    const querySnapshot = this.firestore.collection('admins').get().subscribe(querySnapshot => {
      let data: any = querySnapshot.docs.map(doc => ({...doc.data(), id: doc.id}));
      this.admins = data.map((e, i) => ({
        index: i + 1,
        id: e.id,
        username: e.username,
        role: e.role,
        name: e.name,
        status: e.status
      }));
    });
  }
  createNewAdmin() {
    this.firestore.collection('admins').add({
      username: this.newAdmin.username,
      password: this.newAdmin.password,
      name: this.newAdmin.name,
      role: this.newAdmin.role,
      status: this.newAdmin.status
    }).then(querySnapshot => {
      this.reloadList();
    })
  }
  updateAdmin() {
    this.firestore.doc(`admins/${this.selectedAdmin.id}`).update({
      username: this.selectedAdmin.username,
      ...(this.selectedAdmin.password ? {password: this.selectedAdmin.password} : {}),
      name: this.selectedAdmin.name,
      role: this.selectedAdmin.role,
      status: this.selectedAdmin.status
    }).then(querySnapshot => {
      this.reloadList();
    })
  }
  clearNewAdminForm() {
    this.newAdmin = {
      index: 0,
      id: '',
      username: '',
      password: '',
      name: '',
      role: 'operator',
      status: 'active'
    }
  }
  setTarget(admin) {
    this.selectedAdmin = { ...admin };
  }
}
